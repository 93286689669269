import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from 'lodash'

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    'messages.error.server_error': t('messages.error.server_error')
  };
  const refListTable = ref(null)
  const toast = useToast()

  store.dispatch('cm_master_account/getAll')
  store.dispatch('tenant/getAll')

  const masterAccountOptions = computed(() => store.getters['cm_master_account/getSelectedItems'])
  const masterAccountFilter = ref(null)
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  if (router.currentRoute.params.id_master_account)
    masterAccountFilter.value = parseInt(router.currentRoute.params.id_master_account)

  const tableColumns = [
    { label: 'actions.name', key: 'actions' },
    { label: 'reseller.name', key: 'firstName', sortable: true },
    { label: 'reseller.lastname', key: 'lastName', sortable: true },
    { label: 'reseller.email', key: 'contactEmail', sortable: true },
    { label: 'reseller.number_of_cameras', key: 'cameras_count', sortable: false },
    { label: 'reseller.cm_number_of_cameras', key: 'cm_cameras_count', sortable: false },
    { label: 'reseller.max_amount_cameras', key: 'max_amount_cameras', sortable: true },
    { label: 'reseller.invoice_period', key: 'invoice_period', sortable: true },
    { label: 'reseller.quality', key: 'quality', sortable: true },
    { label: 'tenants.title', key: 'tenant.name', sortable: true },
    { label: 'master_account.title', key: 'camera_manager_master_account.user', sortable: true },
    { label: 'reseller.update_password_periodically', key: 'update_password', sortable: true },
    { label: 'reseller.address', key: 'address', sortable: true },
    { label: 'reseller.country', key: 'country', sortable: true },
    { label: 'reseller.business', key: 'companyName', sortable: true },
    { label: 'reseller.time_zone', key: 'timeZone', sortable: true },
    {
      label: 'reseller.last_login', key: 'dateSignup', sortable: true, formatter: 'formatDateAssigned',
    },
  ]

  if(ability.can('read_reseller_password', 'CameraManager'))
    tableColumns.splice(4,0, { label: 'reseller.password', key: 'password', sortable: false })

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('cm_reseller/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      master_account: masterAccountFilter.value,
      tenant: tenantFilter.value
    })
    .then(response => {
      callback(response.data)
      totalList.value = response.meta.total
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: trans['messages.error.server_error'],
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([masterAccountFilter, tenantFilter, currentPage, perPage, ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    masterAccountOptions,
    masterAccountFilter,
    tenantOptions,
    tenantFilter,
    refetchData,
  }
}
