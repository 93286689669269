<template>
  <b-sidebar
    id="edit-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ $t("actions.edit") + " " + $tc("reseller.title", 1) }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Tenant -->
          <validation-provider
            #default="validationContext"
            name="Tenant"
            rules="required"
          >
            <b-form-group
              label="Tenant"
              label-for="id_tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tenant"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- update password -->
          <validation-provider name="Actualizar Password" rules="required">
            <b-form-checkbox
              id="checkbox-1"
              v-model="itemData.update_password"
              name="update_password"
              value="1"
              unchecked-value="0"
              class="my-1"
              switch
            >
              {{ $t("reseller.update_password_periodically") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- firstName -->
          <validation-provider name="Nombres">
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.name')"
              label-for="firstName"
            >
              <b-form-input id="firstName" disabled v-model="itemData.firstName" trim />
            </b-form-group>
          </validation-provider>

          <!-- lastName -->
          <validation-provider name="Apellidos">
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.lastname')"
              label-for="lastName"
            >
              <b-form-input id="lastName" disabled v-model="itemData.lastName" trim />
            </b-form-group>
          </validation-provider>

          <!-- address -->
          <validation-provider name="Dirección">
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.address')"
              label-for="address"
            >
              <b-form-input id="address" disabled v-model="itemData.address" trim />
            </b-form-group>
          </validation-provider>

          <!-- country -->
          <validation-provider name="País">
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.country')"
              label-for="country"
            >
              <b-form-input id="country" disabled v-model="itemData.country" trim />
            </b-form-group>
          </validation-provider>

          <!-- companyName -->
          <validation-provider name="Empresa">
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.business')"
              label-for="companyName"
            >
              <b-form-input
                id="companyName"
                disabled
                v-model="itemData.companyName"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- companyName -->
          <validation-provider name="Zona Horaria">
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.time_zone')"
              label-for="timeZone"
            >
              <b-form-input id="timeZone" disabled v-model="itemData.timeZone" trim />
            </b-form-group>
          </validation-provider>

          <!-- companyName -->
          <validation-provider
            name="Contrasena"
            v-if="$can('read_reseller_password', 'CameraManager')"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('reseller.password')"
              label-for="password"
            >
              <b-form-input id="password" disabled v-model="itemData.password" trim />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSidebarActive",
    event: "update:is-edit-sidebar-active",
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      firstName: "",
      lastName: "",
      id_tenant: null,
      master_account: "",
      address: "",
      country: "",
      companyName: "",
      timeZone: "",
      dateSignup: "",
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      const req = {
        id: itemData.value.id,
        params: {
          id_tenant: itemData.value.id_tenant,
          update_password: itemData.value.update_password,
        },
      };

      store
        .dispatch("cm_reseller/edit", req)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-edit-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      itemData.value = props.itemEdit;
    };

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>