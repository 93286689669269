<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>
        {{ $t("filters") }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc("master_account.title") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="masterAccountFilter"
            :options="masterAccountOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:masterAccountFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc("tenants.title") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            :reduce="(val) => val.value"
            multiple
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mt-auto">
          <b-button
            variant="secondary"
            class="float-right text-capitalize"
            :disabled="buttonUpdate"
            @click="$emit('update-reseller-data')"
            >{{ $t("actions.update") + " " + $tc("reseller.title", 1) }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    masterAccountOptions: {
      type: Array,
      default: null,
    },
    masterAccountFilter: {
      type: Number,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    buttonUpdate: {
      type: Boolean,
      default: null,
    },
  },
};
</script>